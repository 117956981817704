import { useState } from 'react';
import { globalIp } from '../Global/config';
import Modal from 'react-modal';

export const WarningModal = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const currentYear = new Date().getFullYear();
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            innerWidth: '500px',
            innerHeight: '300px',
        },
    };
    return (
        <div>
            <p>© <span>{currentYear}</span>, ИП Горохов. Все права защищены. <button onClick={() => setModalIsOpen(true)}>Все ресурсы сайта защищены законом ГК РФ.</button></p>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={customStyles}
            >
                <div
                    className={'body-1'}
                >
                    Все ресурсы сайта <a href={globalIp} target={'_blank'} rel={'noreferrer'}>{globalIp}</a>, включая текстовую, графическую и видео информацию, структуру и оформление страниц,
                    защищены российскими и международными законами и соглашениями об охране авторских прав и интеллектуальной собственности
                    (статьи 1259 и 1260 главы 70 "Авторское право" Гражданского Кодекса Российской Федерации от 18 декабря 2006 года N 230-ФЗ).
                    Использование любых материалов сайта разрешено только с письменного согласия владельцев ресурса <a href={globalIp}>{globalIp}</a>, со ссылкой на оригинал в виде: "источник - <a href={globalIp}>{globalIp}</a>"
                </div>
                <button
                    onClick={() => setModalIsOpen(false)}
                    style={{padding: '12px', border: '1px solid #888686', borderRadius: '6px', marginTop: '12px'}}
                >
                    Закрыть
                </button>
            </Modal>
        </div>
    )
}