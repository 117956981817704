import classNames from 'classnames';
import './services.scss';

export const Services = () => {
    return (
        <div
            className={'container'}
        >
            <h2
                className={classNames('subtitle-2', 'table-header')}
            >
                ПРЕЙСКУРАНТ на ортопедические услуги в ИП Горохов М.Ю.
            </h2>
            <table
                className={'price-table'}
            >
                <colgroup>
                    <col style={{ width: "14%" }} />
                    <col style={{ width: "76%" }} />
                    <col style={{ width: "8%" }} />
                </colgroup>
                <tbody>
                    <tr>
                        <td>А16.07.035</td>
                        <td><strong>Протезирование частичными съёмными пластиночными протезами
                            (1 челюсть)</strong></td>
                        <td><strong>15000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.035</td>
                        <td><p><strong>Протезирование частичными съёмными пластиночными
                            протезами с армированием</strong></p>
                            <p><strong>(1 челюсть)</strong></p></td>
                        <td><strong>16000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.035</td>
                        <td><p><strong>Протезирование частичными съёмными пластиночными
                            протезами из нейлона</strong></p>
                            <p><strong>(1 челюсть)</strong></p></td>
                        <td><strong>23000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.023</td>
                        <td><strong>Протезирование зубов полными съёмными пластиночными
                            протезами (1 челюсть)</strong></td>
                        <td><strong>17000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.023</td>
                        <td><p><strong>Протезирование зубов полными съёмными пластиночными
                            протезами с армированием</strong></p>
                            <p><strong>(1 челюсть)</strong></p></td>
                        <td><strong>18000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.023</td>
                        <td><p><strong>Протезирование зубов полными съёмными пластиночными
                            протезами из нейлона</strong></p>
                            <p><strong>(1 челюсть)</strong></p></td>
                        <td><strong>26000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.036</td>
                        <td><strong>Протезирование съёмными бюгельными протезами с замковой
                            фиксацией (1 челюсть)</strong></td>
                        <td><strong>43000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.028</td>
                        <td><strong>Снятие коронки (цельнометаллической, металлокерамической) 1
                            единица</strong></td>
                        <td><strong>900,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.028</td>
                        <td><strong>Снятие штампованной коронки - 1 единица</strong></td>
                        <td><strong>500,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.053</td>
                        <td><strong>Снятие несъёмной ортопедической конструкции с
                            импланта</strong></td>
                        <td><strong>1200,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.092</td>
                        <td><strong>Трепанация зуба, трепанация искусственной
                            коронки</strong></td>
                        <td><strong>700,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.049</td>
                        <td><strong>Повторная фиксация на постоянный цемент несъёмных
                            ортопедических конструкций (1 единица)</strong></td>
                        <td><strong>800,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.049</td>
                        <td><strong>Повторная фиксация на постоянный цемент несъёмных
                            ортопедических конструкций с опорой на имплантах (1
                            единица)</strong></td>
                        <td><strong>1200,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.049</td>
                        <td><strong>Временная фиксация ортопедических конструкций (1
                            единица)</strong></td>
                        <td><strong>500,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.035</td>
                        <td><strong>Приварка кламера</strong></td>
                        <td><strong>900,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.036</td>
                        <td><strong>Приварка зуба</strong></td>
                        <td><strong>900,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.037</td>
                        <td><strong>Починка перелома базиса самотвердеющей
                            пластмассой</strong></td>
                        <td><strong>900,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.035</td>
                        <td><strong>Эластичная прокладка для съёмного протеза</strong></td>
                        <td><strong>800,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.035</td>
                        <td><strong>Полировка пластиночного протеза</strong></td>
                        <td><strong>600,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.035</td>
                        <td><strong>Профессиональная чистка съёмного протеза</strong></td>
                        <td><strong>600,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.035</td>
                        <td><strong>Замена матрицы</strong></td>
                        <td><strong>1000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07035</td>
                        <td><strong>Искусственная керамическая десна</strong></td>
                        <td><strong>1800,00</strong></td>
                    </tr>
                    <tr>
                        <td>А02.07.010.001</td>
                        <td><strong>Снятие оттиска с одной челюсти альгинатной
                            массой</strong></td>
                        <td><strong>350,00</strong></td>
                    </tr>
                    <tr>
                        <td>А02.07.010.001</td>
                        <td><strong>Снятие оттиска с одной челюсти массой из
                            С-силикона</strong></td>
                        <td><strong>1500,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.033</td>
                        <td><strong>Восстановление зуба с использованием цельнолитой культевой
                            вкладки (1 единица)</strong></td>
                        <td><strong>2400,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.033</td>
                        <td><strong>Восстановление зуба с использованием культевой вкладки,
                            разборной (1 единица)</strong></td>
                        <td><strong>3600,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.033</td>
                        <td><strong>Восстановление зуба коронкой временным прямым методом (1
                            единица)</strong></td>
                        <td><strong>600,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.033</td>
                        <td><strong>Восстановление зуба коронкой временной композитной
                            фрезерованной лабораторным методом (1 единица)</strong></td>
                        <td><strong>1800,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.028</td>
                        <td><strong>Изготовление коронки цельнолитой</strong></td>
                        <td><strong>6000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.054</td>
                        <td><p><strong>Восстановление зуба коронкой постоянной
                            цельнометаллической с напылением</strong></p>
                            <p><strong>(1 единица)</strong></p></td>
                        <td><strong>6400,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.054</td>
                        <td><strong>Восстановление зуба коронкой постоянной металлокерамической
                            (1 единица)</strong></td>
                        <td><strong>8600,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.054</td>
                        <td><p><strong>Восстановление зуба коронкой постоянной безметалловой из
                            диоксида циркония</strong></p>
                            <p><strong>(1 единица)</strong></p></td>
                        <td><strong>16800,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.054</td>
                        <td><strong>Восстановление зуба коронкой постоянной безметалловой Имакс
                            с индивидуальной эстетикой (метод нанесения) - (1 единица)</strong></td>
                        <td><strong>19000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.054</td>
                        <td><strong>Восстановление зуба коронкой постоянной безметалловой из
                            диоксида циркония с индивидуальной эстетикой (1 единица)</strong></td>
                        <td><strong>19800,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.002.030</td>
                        <td><strong>Изготовление коронки пластмассовой</strong></td>
                        <td><strong>1000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А23.07.030</td>
                        <td><strong>Использование индивидуальной ложки при снятии оттиска с
                            одной челюсти</strong></td>
                        <td><strong>1500,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.036</td>
                        <td><strong>Протезирование съёмными бюгельными протезами с кламмерной
                            фиксацией</strong></td>
                        <td><strong>33000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А 23.07.002.034</td>
                        <td><strong>Перебазировка съёмного протеза</strong></td>
                        <td><strong>700,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.003</td>
                        <td><strong>Восстановление зуба виниром</strong></td>
                        <td><strong>19200,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.003</td>
                        <td><strong>Восстановление зуба вкладкой из диоксида
                            циркония</strong></td>
                        <td><strong>4800,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.003</td>
                        <td><strong>Винт</strong></td>
                        <td><strong>800,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.003</td>
                        <td><strong>Абатмент</strong></td>
                        <td><strong>16000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.003</td>
                        <td><strong>Коронка из диоксида циркония на имплант</strong></td>
                        <td><strong>19000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.003</td>
                        <td><strong>Установка формирователя</strong></td>
                        <td><strong>5000,00</strong></td>
                    </tr>
                    <tr>
                        <td>А16.07.003</td>
                        <td><strong>Снятие слепков с трансфером с одной челюсти</strong></td>
                        <td><strong>3000,00</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}