import classNames from 'classnames';
import './about.scss';
import { WarningModal } from '../WarningModal/warningModal';

export const About = () => {
    const telephone = '89145522529';
    const email = 'gorokhovmu@mail.ru';

    return (
        <div
            className={classNames('container', 'about')}
        >
            <div
                className={'about__info'}
            >
                <div className={'info-block'}>
                    <p
                        className={'body-1'}
                    >
                        Наш адрес:
                    </p>
                    <p
                        className={'body-2'}
                    >
                        Амурская область, город Свободный, улица Чехова, дом 40
                    </p>
                </div>
                <div className={'info-block'}>
                    <p
                        className={'body-1'}
                    >
                        Телефон:
                    </p>
                    <a
                        href={`tel: ${telephone}`}
                        className={'body-2'}
                    >
                        {telephone}
                    </a>
                </div>
                <div className={'info-block'}>
                    <p
                        className={'body-1'}
                    >
                        e-mail:
                    </p>
                    <a
                        href={`mailto:${email}`}
                        className={'body-2'}
                    >
                        {email}
                    </a>
                </div>
            </div>
            <div className={'info-block'}>
                <p className={'body-1'}>
                    Как нас найти
                </p>
                <iframe title={'map'} src="https://yandex.ru/map-widget/v1/?um=constructor%3A29c6bd80a36a02ac74dfac100cb7e7db99e895d0575bc7a2a8571c36e4c6def5&amp;source=constructor" width="100%" height="500"></iframe>
            </div>
            <WarningModal/>
        </div>
    )
}