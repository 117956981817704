export const documentsInside = [
    {
        name: 'Выписка из реестра лицензий',
        href: 'assets/files/licenseIp.pdf',
    },
    {
        name: 'Лицензия ИП',
        href: 'assets/files/license-original.pdf',
    },
    {
        name: 'Свидетельства ИНН и ОГРН',
        href: 'assets/files/inn-ogrn.pdf',
    },
    {
        name: 'Договор возмездного оказания медицинских услуг',
        href: 'assets/files/dogovor-ip.pdf',
    },
    {
        name: 'Адреса и телефоны органов контроля',
        href: 'assets/files/inf-for-clients.pdf',
    },
];

export const governmentDocuments = [
    {
        name: 'Закон о сан-эпид благополучии населения',
        href: 'assets/files/52FL.pdf',
    },
    {
        name: 'Закон о защите прав потребителей',
        href: 'assets/files/2300FL.pdf',
    },
    {
        name: 'Постановление правительства РФ 736 Правила оказания платных медицинских услуг взрослому населению в РФ',
        href: 'assets/files/N736.pdf',
    },
    {
        name: 'Распоряжение правительства РФ от 12 октября 2019г №2406-р',
        href: 'assets/files/rasporiygenie_2406.pdf',
    },
    {
        name: 'Перечень жизненно-необходимых и важнейших лекарственных препаратов для медицинского применения',
        href: 'assets/files/neobhodim-lecarstva.pdf',
    },
];
