import React from 'react';
import { Helmet } from 'react-helmet';

const IndexHtmlConfig = () => {
    return (
        <Helmet>
            <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js'></script>
            <script src="https://lidrekon.ru/slep/js/jquery.js"></script>
            <script src="https://lidrekon.ru/slep/js/uhpv-full.min.js"></script>
        </Helmet>
    );
};

export default IndexHtmlConfig;
