import { Menu } from '../Menu/menu';
import './header.scss';

const Logo = () => {
    return (
        <div>
            <p
                className={'subtitle-2'}
            >
                ИП Горохов
            </p>
        </div>
    )
}

export const Header = () => {
    return (
        <div
            className={'header'}
        >
            <Logo />
            <Menu />
        </div>
    )
}