import { Link, useLocation } from 'react-router-dom';
import './menu.scss';
import classNames from 'classnames';
import { useState } from 'react';

const menuItems = [
    {
        name: 'services',
        routePath: '/',
        visibleName: 'Услуги',
    },
    {
        name: 'about',
        routePath: '/about',
        visibleName: 'О нас',
    },
    {
        name: 'documents',
        routePath: '/documents',
        visibleName: 'Документация',
    },
]

export const Menu = () => {
    const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false);
    const location = useLocation();

    return (
        <div
            className={'main-menu'}
        >
            <div
                className={'main-menu__links'}
            >
                {
                    menuItems.map(item =>
                        <Link
                            to={item.routePath}
                            className={classNames(
                                'main-menu__item',
                                'body-1',
                                location.pathname === item.routePath && 'main-menu__item--checked',
                            )}
                        >
                            {item.visibleName}
                        </Link>
                    )
                }
            </div>
            <button
                className={'burger-menu'}
                onClick={() => setShowBurgerMenu(!showBurgerMenu)}
            >
                Меню
            </button>
            {
                showBurgerMenu &&
                <div
                    className={'burger-menu__block'}
                >
                    {
                        menuItems.map(item =>
                            <Link
                                to={item.routePath}
                                className={classNames(
                                    'main-menu__item',
                                    'body-1',
                                    location.pathname === item.routePath && 'main-menu__item--checked',
                                )}
                                onClick={() => setShowBurgerMenu(false)}
                            >
                                {item.visibleName}
                            </Link>
                        )
                    }
                </div>
            }
            <img id="specialButton" src="https://lidrekon.ru/images/special.png" alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" />
        </div>
    )
}