import './styles/app.scss';
import { Route, Routes } from 'react-router-dom';
import { Header } from './modules/Header/header';
import { About } from './modules/About/about';
import { useEffect } from 'react';
import { Documents } from './modules/Documents/documents';
import { Services } from './modules/Services/services';

function App() {
  useEffect(() => {
    const loadScripts = async () => {
      try {
        await loadScript("https://lidrekon.ru/slep/js/jquery.js", 'slep-js-jquery');
        await loadScript("https://lidrekon.ru/slep/js/uhpv-full.min.js", 'uhpv-full');
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    };

    const loadScript = (src: string, id: string) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.id = id;
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    loadScripts();
    return () => {
      const elementScriptFirst = document.body.querySelector('script[src="https://lidrekon.ru/slep/js/jquery.js"]');
      const elementScriptSecond = document.body.querySelector('script[src="https://lidrekon.ru/slep/js/uhpv-full.min.js"]');

      if (!elementScriptFirst || !elementScriptSecond) return;
      // Clean up on component unmount
      document.body.removeChild(elementScriptFirst);
      document.body.removeChild(elementScriptSecond);
    };
  }, []);

  return (
    <>
      <div className="App">
        <Header />
        <main className='main'>
          <Routes>
            <Route path='/about' element={<About />} />
            <Route path='/documents' element={<Documents/>}/>
            <Route path='/services' element={<Services/>}/>
            <Route path='/' element={<Services/>}/>
          </Routes>
        </main>
      </div>
    </>
  );
}

export default App;
