import classNames from 'classnames';
import './documents.scss';
import { documentsInside, governmentDocuments } from './consts';

export const Documents = () => {
    
    return (
        <div className={classNames('container', 'documents')}>
            <div
                className={'documents__block'}
            >
                <h2 className={'subtitle-2'}>
                    Внутренние нормативные документы:
                </h2>
                <ul>
                    {
                        documentsInside.map((document) =>
                            <li>
                                <a
                                    href={document.href}
                                    className={'body-1'}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                >
                                    {document.name}
                                </a>
                            </li>
                        )
                    }
                </ul>
            </div>
            <div
                className={'documents__block'}
            >
                <h2 className={'subtitle-2'}>
                    Законодательство РФ:
                </h2>
                <ul>
                    {
                        governmentDocuments.map((document) =>
                            <li>
                                <a
                                    href={document.href}
                                    className={'body-1'}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                >
                                    {document.name}
                                </a>
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
